<template>
    <div class="content">
      <div class="content-img">
        <van-image :src="require('@/assets/img/success.png')"></van-image>
      </div>
      <div class="text-father">
        <p>{{name}}{{sex === 2 ? '女士' : '先生'}}</p>
        <p class="text-p">{{msg}}</p>
      </div>
    </div>
</template>

<script>
export default {
  data() {
    return {
      name: '',
      sex: '',
      activityId: 0,
      msg: ''
    }
  },
  created() {
    if (this.$route.query && this.$route.query.ids) {
      this.activityId = this.$route.query.ids
    }
    this.getUser()
    this.signIn()
  },
  methods: {
    getUser() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/user/my/info'),
        method: 'get',
        params: this.$http.adornParams({
          id: this.$globalData.userInfo.userId,
          orgId: this.$orgId
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.sex = data.userManager.sex
          this.name = data.userManager.name
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    signIn() {
      this.$http({
        url: this.$http.adornUrl(`/wxapp/activity/sign/sign`),
        method: 'post',
        params: this.$http.adornParams({
          activityId: this.activityId,
          userId: this.$globalData.userInfo.userId,
          community: this.$orgId
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.msg = '本次活动您已签到成功！'
        } else {
          this.msg = data.msg
        }
      })
    }
  }

}
</script>

<style scoped>
.content {
  height: 10.5rem;
  width: 8rem;
  background-color: white;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 15px;
  padding-top: 80px;
}
.content-img {
  width: 3rem;
  margin: 0 auto;
}
.x-close-dialog {
  font-size: 0.6rem;
  text-align: right;
  padding: 0.3rem 0.4rem 0 0
}
.text-father {
  font-size: 0.5rem;
  font-weight: bold;
  display: inline-block;
  width: 100%;
  text-align: center;
  line-height: 0.8rem;
  margin-top: 0.5rem;
  padding: 0 1rem;
}
.text-p {
  color: red;
  font-size: 0.5rem;
}
</style>
